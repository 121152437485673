






























































































import Vue, { PropType, VueConstructor } from "vue";
import { createNamespacedHelpers } from "vuex";

import { Business, Role, Service, ServiceCategory } from "@/types";
import serviceStoreModule from "@/store/modules/service";

const VUE_APP_API_URL = process.env.VUE_APP_API_URL;

interface ServiceListClass {
  fetchServiceList(params: string): void;
  editService(service: Service): Service;
}

export default (Vue as VueConstructor<Vue & ServiceListClass>).extend<
  any,
  any,
  any,
  any
>({
  name: "ServiceList",
  props: {
    category: {
      type: Object as PropType<ServiceCategory>,
      required: true,
    },
  },
  data: () => ({
    service: undefined,
    showDeleteDialog: false,
    apiUrl: VUE_APP_API_URL,
    headers: [
      {
        text: "Name",
        align: "start",
        sortable: true,
        value: "name",
      },
      { text: "Duration", value: "durationInMinutes" },
      { text: "Action", value: "action" },
    ],
    options: {} as { page: number; itemsPerPage: number },
  }),
  watch: {
    options: {
      handler() {
        this.fetchServices();
      },
      deep: true,
    },
  },
  created() {
    this.fetchServices();
  },
  computed: {
    role(): Role {
      return this.$store.getters.role;
    },
  },
  methods: {
    fetchServices() {
      if (this.role) {
        const bid = (this.role.business as Business)._id;
        const cid = this.category._id;
        const page = this.options.page || 1;
        let limit = this.options.itemsPerPage || 10;
        if (limit === -1) {
          limit = 1000000000;
        }
        const params = `?businessId=${bid}&categoryId=${cid}&page=${page}&limit=${limit}`;
        this.fetchServiceList(params);
      }
    },
  },
  beforeCreate() {
    const namespace = `SERVICE_LIST_${
      (this.$options.propsData as { category: ServiceCategory })?.category._id
    }`;
    if (!this.$store.hasModule(namespace)) {
      this.$store.registerModule(namespace, serviceStoreModule);
    }
    const { mapGetters, mapActions } = createNamespacedHelpers(namespace);

    this.$options.computed = {
      ...(this.$options.computed || {}),
      ...mapGetters(["servicePage"]),
    };

    this.$options.methods = {
      ...(this.$options.methods || {}),
      ...mapActions(["fetchServiceList", "deleteService"]),
    };
  },
  beforeDestroy() {
    const namespace = `SERVICE_LIST_${
      (this.$options.propsData as { category: ServiceCategory })?.category._id
    }`;
    this.$store.unregisterModule(namespace);
  },
});
